import React from 'react';
import Twitter from './twitter';
import Instagram from './instagram';
import Mail from './mail';
import Braze from './braze';

function Icon(props) {
	switch (props.symbol) {
		case 'eye':
			return <TattooEye />;
		case 'twitter':
			return <Twitter />;
		case 'instagram':
			return <Instagram />;
		case 'mail':
			return <Mail />;
		case 'braze':
			return <Braze />;
		default:
			return <span>Unknown icon: {props.symbol}</span>;
	}
}

export default Icon;
