import React from 'react';
import { Link } from 'gatsby';
import Container from '../components/container';
import Icon from '../components/icons';

import styles from './header.module.css';

const Header = (data) => (
	<Container>
		<div className={styles.navContainer}>
			<nav>
				<Link to="/">Work</Link>
        <span>&#8212;</span>
				<Link to="/about/">About</Link>
        <span>&#8212;</span>
				<Link to="/blog/">Blog</Link>
			</nav>

			<div className={styles.socialNav}>
				<a href={data.siteTwitter} target="_blank" rel="noopener">
        <Icon symbol="twitter" />
				</a>
        <span>&#8212;</span>
				<a href={`${data.siteInstagram}`} target="_blank" rel="noopener">
        <Icon symbol="instagram" />
				</a>
        <span>&#8212;</span>
				<a href={`${data.siteEmail}`} target="_blank" rel="noopener">
        <Icon symbol="mail" />
				</a>
			</div>
		</div>
	</Container>
);

export default Header;
