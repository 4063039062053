import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const detailsQuery = graphql`
	query SEOQuery {
		site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
			title
			description
			keywords
			author
		}
	}
`;

function SEO({ description, lang, meta, keywords = [], title }) {
	return (
		<StaticQuery
			query={detailsQuery}
			render={(data) => {
				if (!data.site) {
					return;
				}
				const metaDescription = description || data.site.description;
				return (
					<Helmet
						htmlAttributes={{
							lang
						}}
						title={title}
						titleTemplate="%s • Jeffrey Herrera"
						defaultTitle="Jeffrey Herrera • Designer"
						link={[
							{
								rel: 'icon',
								type: 'image/png',
								sizes: '16x16',
								href: '/favicon-16x16.png'
							}
						]}
						link={[
							{
								rel: 'icon',
								type: 'image/png',
								sizes: '32x32',
								href: '/favicon-32x32.png'
							}
						]}
						link={[
							{
								rel: 'apple-touch-icon',
								type: 'image/png',
								sizes: '180x180',
								href: '/apple-touch-icon.png'
							}
						]}
						link={[
							{
								rel: 'manifest',
								href: '/site.webmanifest'
							}
						]}
						meta={[
							{
								name: 'description',
								content: metaDescription
							},
							{
								property: 'og:title',
								content: title
							},
							{
								property: 'og:description',
								content: metaDescription
							},
							{
								property: 'og:type',
								content: 'website'
							},
							{
								name: 'twitter:card',
								content: 'summary'
							},
							{
								name: 'twitter:creator',
								content: data.site.author
							},
							{
								name: 'twitter:title',
								content: title
							},
							{
								name: 'twitter:description',
								content: metaDescription
							}
						]
							.concat(
								keywords && keywords.length > 0
									? {
											name: 'keywords',
											content: keywords.join(', ')
										}
									: []
							)
							.concat(meta)}
					/>
				);
			}}
		/>
	);
}

SEO.defaultProps = {
	lang: 'en',
	meta: [],
	keywords: []
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.array,
	keywords: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string.isRequired
};

export default SEO;
