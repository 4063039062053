import React from 'react'
import Header from './header'

import '../styles/layout.css'
import styles from './layout.module.css'

const Layout = ({ children, siteTwitter, siteInstagram, siteEmail, siteTitle }) => (
  <>
    <Header siteTitle={siteTitle} siteTwitter={siteTwitter} siteInstagram={siteInstagram} siteEmail={siteEmail} />
    <div className={styles.content}>{children}</div>
    <footer className={styles.footer}>
    </footer>
  </>
)

export default Layout
